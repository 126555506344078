import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Advertise from "../components/Advertise";
import StartBtn from "../assets/buttons/StartBut.jpg"

const Home = ({ setMin, onIncrement }) => {
    const [formattedDate, setFormattedDate] = useState();
    const [timer, setTimer] = useState();
    const timerMinutes = [1, 2, 3, 4, 5];
    const navigate = useNavigate();

    const handleChange = (e) => {
        setTimer(e.target.value);
        setMin(e.target.value);
    };

    const handleStart = () => {
        onIncrement();
        navigate("/play");
    }

    //Get Current Date e.g. Today is Monday, September 16, 2024
    useEffect(() => {
        const currenDate = new Date();
        const tempDate = new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(currenDate);

        setFormattedDate(tempDate);
    }, []);

    //Default timer to 5
    useEffect(() => {
        setMin(5);
    }, [setMin])

    return (
        <div className="min-h-screen flex flex-col items-center justify-center py-2">
            {/* Header with logo and Date*/}
            <div className=" w-full max-w-lg text-center p-4">
                <Header />
                <p className="text-sm text-red-500 mt-6">
                    <span>Today is </span>{formattedDate}
                </p>
            </div>

            {/* Instructions section */}
            <div className="p-4 w-full max-w-lg text-center">
                <p className="text-sm mb-4 text-justify">
                    Take 6 chances to guess 4 digit number within 1-5 minutes.
                    Your guesses cannot have repeated digits.
                    After submitting each guess your answer will be displayed with each digit color coded.&nbsp;
                    <span className="text-gray-500 font-bold">GRAY</span>, digit not in number;&nbsp;
                    <span className="text-blue-500 font-bold">BLUE</span>, digit in number but wrong position;&nbsp;
                    <span className="text-red-500 font-bold">RED</span>, digit in number and correct position.
                    When you guess the correct number all digits will be displayed in&nbsp;
                    <span className="text-red-500 font-bold">RED</span>
                </p>
                <p className="text-sm mb-4">
                    Set TIMER minutes 1-5 (default 5), then press START to play.
                </p>

                {/* Timer input */}
                <div className="flex justify-center items-center mb-4">
                    <label htmlFor="timer" className="mr-2 text-sm font-bold">TimerMinutes:</label>
                    <select
                        className="h-10 w-12 border border-gray-300 text-gray-600 text-sm rounded-lg block px-1 focus:outline-none"
                        onChange={handleChange}
                    >
                        {timerMinutes.map((time, index) => {
                            return (
                                time === timer
                                    ?
                                    <option key={index} value={time} selected>{time}</option>
                                    :
                                    <option key={index} value={time}>{time}</option>
                            )
                        })}
                    </select>
                </div>

                {/* Start button */}
                <img
                    src={StartBtn}
                    alt='Start Button'
                    className='mr-auto ml-auto w-[400px] h-[120px] cursor-pointer'
                    onClick={handleStart}
                />

                {/* Ad Space */}
                <div className=" w-full max-w-lg text-center py-6">
                    <Advertise />
                </div>
            </div>
        </div>
    );
};

export default Home;
