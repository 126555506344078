import React from "react";
import GuessNumberLogo from "../assets/guess-number-logo.jpg"

const Header = () => {
    return (
        <div>
            <img
                src={GuessNumberLogo}
                alt="Guess Number Logo"
                className="w-[500px] h-[150px] mt-1 rounded-3xl"
            />
        </div>
    )
}

export default Header;
