import React from "react";
import GuessNumberAd from "../assets/guess-number-ad.jpg"

const Advertise = () => {
    return (
        <div>
            <a href="https://899f10ua8x7w2u7dgrnz2b-p3c.hop.clickbank.net" target="_blank">
                <img
                    src={GuessNumberAd}
                    alt="Guess Number Ad"
                    className="w-[660px] h-[400px] mt-1 rounded-3xl"
                />
            </a>
        </div>
    )
}

export default Advertise;
